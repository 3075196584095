<template>
  <page-title-component title="Agency"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table-component
            :columns="columns"
            :rows="agencies"
            :sortable="sortable"
            @sorting="sorting"
            :pagination="pagination"
            @search="search"
            @pageChanged="pageChanged">

            <template v-slot:actions>
              <div class="row">
                <div class="col-4">
                  <select class="form-control form-control-sm"
                          @change="loadData"
                          v-model="queryParams.type">
                    <option value="">All Agency</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div class="col-4">
                  <button class="btn btn-sm btn-primary text-wrap"
                          @click="$refs.addAgencyModal.show(null)">Add Agency</button>
                </div>
              </div>
            </template>

            <template v-slot:action="{row}">
              <button class="btn btn-sm btn-primary mr-2"
                      @click="$refs.addAgencyModal.show(row)">Edit</button>

              <button class="btn btn-sm btn-primary"
                      @click="$refs.invoiceModal.show(row.id)">Invoices</button>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-agency-modal @added="loadData" ref="addAgencyModal" />
  <invoice-modal ref="invoiceModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import TableComponent from "@/components/TableComponent";
import AddAgencyModal from "@/views/agency/AddAgencyModal.vue";
import InvoiceModal from "@/views/agency/InvoiceModal.vue";
export default {
  components: {TableComponent, PageTitleComponent, AddAgencyModal, InvoiceModal},
  data() {
    return {
      agencies: [],
      queryParams: {
        search: '',
        type: '',
        search_columns: '',
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          searchable: true,
        },
        {
          label: "Email",
          field: "email",
          sortable: true,
          searchable: true,
        },
        {
          label: "Full Price",
          field: "email",
          sortable: true,
          searchable: false,
          display(row) {
            return '$' + row.full_price;
          }
        },
        {
          label: "Influencer Price",
          field: "email",
          sortable: true,
          searchable: false,
          display(row) {
            return '$' + row.influencer_price;
          }
        },
        {
          label: "License",
          field: "licenses",
          sortable: false,
          searchable: false,
          display(row) {
            return row.licenses.length;
          }
        },
        {
          label: "Active License",
          field: "licenses",
          sortable: false,
          searchable: false,
          display(row) {
            return row.licenses.filter(l => l.activated === 1).length;
          }
        },
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Activated At",
          field: "activated_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Trial Ends At",
          field: "trial_ends_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          class: ['no-wrap text-right'],
          slot: true
        },
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.agencies = [];

      this.axios.get('/agencies', {params: this.queryParams})
        .then((response) => {
          this.agencies = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>