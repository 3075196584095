<template>
  <span class="badge badge-info" v-if="status === 'open'">Open</span>
  <span class="badge badge-success" v-else-if="status === 'paid'">Paid</span>
  <span class="badge badge-danger" v-else-if="status === 'uncollectible'">Uncollectible</span>
  <span class="badge badge-dark" v-else-if="status === 'void'">Void</span>
</template>

<script>
export default {
  props: ['status']
}
</script>