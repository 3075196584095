<template>
  <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-apply-promoLabel">Invoices</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center" v-if="loadingInvoice">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>

          <table v-else class="table table-sm">
            <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Created</th>
              <th>Amount</th>
              <th>Due Data</th>
              <th>Status</th>
              <th class="text-right">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="invoice in invoices" :key="'invoice_'+invoice.id">
              <td>{{ invoice.id }}</td>
              <td>{{ invoice.created }}</td>
              <td>${{ invoice.amount }}</td>
              <td>{{ invoice.due_date }}</td>
              <td>
                <stripe-invoice-status :status="invoice.status" />
              </td>
              <td class="text-right">
                <a v-if="invoice.status === 'open'"
                   href="#"
                   @click.prevent="markPaid(invoice)"
                   title="Mark as Paid"
                   class="btn btn-sm btn-success mr-2">
                  <i class="fa-solid fa-check"></i>
                </a>

                <a v-if="invoice.status === 'open'"
                   href="#"
                   @click.prevent="editInvoice(invoice)"
                   title="Edit"
                   class="btn btn-sm btn-warning mr-2">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>

                <a :href="invoice.pdf_url"
                   target="_blank"
                   title="Invoice"
                   class="btn btn-sm btn-info">
                  <i class="fa-solid fa-file-invoice"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StripeInvoiceStatus from "@/components/StripeInvoiceStatus.vue";
export default {
  components: {StripeInvoiceStatus},
  data() {
    return {
      modal: null,
      invoices: [],
      loadingInvoice: false,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('invoice-modal'))
  },
  methods: {
    show(agencyId) {
      this.loadInvoices(agencyId);
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    markPaid(invoice) {
      this.$swal({
        title: 'Are you sure want to mark as paid?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/invoices/mark-paid', {
            invoice_id: invoice.stripe_invoice_id
          }).then(() => {
            invoice.status = 'paid';
          }).catch((err) => {
            this.showFailMsg(err.response.data.message);
          });
        }
      })
    },
    editInvoice(invoice) {
      this.$swal({
        title: 'Are you sure want to edit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/invoices/edit', {
            invoice_id: invoice.stripe_invoice_id
          }).then((response) => {
            window.open(response.data.url, '_blank');
          }).catch((err) => {
            this.showFailMsg(err.response.data.message);
          });
        }
      })
    },
    loadInvoices(agencyId) {
      this.loadingInvoice = true;
      this.invoices = [];

      this.axios.get('/invoices/' + agencyId)
          .then(res => {
            this.invoices = res.data.data;
          })
          .finally(() => this.loadingInvoice = false)
    }
  }
}
</script>